<template>
  <div class="order-list">
    <van-row type="flex" class="nav">
      <van-col span="6">
        {{ $t('product').User }}
      </van-col>
      <van-col span="8">
        {{ $t('product').Contract }}
      </van-col>
      <van-col span="5">
        {{ $t('product').Direction }}
      </van-col>
      <van-col span="5">
        {{ $t('product').Wager }}
      </van-col>
    </van-row>
    <van-row
      type="flex"
      justify="space-between"
      v-for="(item, index) in list"
      :key="index"
      :class="{ rise: item.Direction === 'Rise' }"
    >
      <van-col span="6">
        {{ item.NickName }}
      </van-col>
      <van-col span="8" class="direction">
        {{ showProductName(item.ProductId) }}
      </van-col>
      <van-col span="5" class="direction">
        <!-- {{
          item.Direction === 'Rise'
            ? $t('product').Increase
            : $t('product').Decrease
        }} -->
        {{ showDirection(item.Direction) }}
      </van-col>
      <van-col span="5"> {{ item.Amount }} <i></i> </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    productList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig']),
  },
  methods: {
    showDirection(Direction) {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return Direction === 'Rise'
          ? basicConfig.RiseName
          : basicConfig.FallName
      } else if (lang === 'en') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameEnglish
          : basicConfig.FallNameEnglish
      } else if (lang === 'ft') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameTraditional
          : basicConfig.FallNameTraditional
      } else {
        return Direction === 'Rise'
          ? basicConfig.RiseNameJapanese
          : basicConfig.FallNameJapanese
      }
    },
    showProductName(ProductId) {
      let lang = this.lang
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === ProductId) {
          if (lang === 'zh') {
            name = item.Name
          } else if (lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })
      return name
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.order-list
  color #666
  background-color: #1f1f1f
  height 50vh
  overflow scroll
  .nav
    background none !important
    .van-col
      color #666 !important
  .van-row
    background #2C3E32
    color #999
    .direction
      color #6BB86D
    .van-col
      height 25px
      line-height 25px
      text-align center
      ellipsis()
      i
        display inline-block
        width 4px
        height 4px
        background  #6BB86D
        margin 2px 3px
        box-shadow 0 0 1px #6BB86D
  .rise
    background #3c252c !important
    .direction
      color #EB4D5C !important
    i
      background  #EB4D5C !important
      box-shadow 0 0 1px #EB4D5C !important
</style>
