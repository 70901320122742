<template>
  <van-popup
    v-model="show"
    round
    position="bottom"
    :close-on-click-overlay="false"
  >
    <div v-for="item in list" :key="item.lang" @click="changeLang(item.lang)">
      {{ item.title }}
    </div>

    <div class="btn" @click="handleClose">取消</div>
  </van-popup>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'

export default {
  props: {
    showLanguage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      list: [],
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  watch: {
    showLanguage(val) {
      this.show = val
    },
  },
  methods: {
    handleClose() {
      this.$emit('closePopup')
    },
    changeLang(lang) {
      this.$i18n.locale = lang
      let basicConfig = this.basicConfig
      this.$store.commit('SET_LANG', lang)
      let title = ''
      if (lang === 'zh') {
        title = basicConfig.CompanyName
      } else if (lang === 'en') {
        title = basicConfig.CompanyNameEnglish
      } else if (lang === 'ft') {
        title = basicConfig.CompanyNameTraditional
      } else {
        title = basicConfig.CompanyNameJapanese
      }
      document.title = title
      localStorage.setItem('language', lang)
      EventBus.$emit('changeLanguage', lang)
      this.handleClose()
    },
  },
  mounted() {
    const LanguageSelect = this.basicConfig.LanguageSelect.join(',')
    if (LanguageSelect.indexOf('Chinese') >= 0) {
      this.list.push({
        title: '中文简体',
        lang: 'zh',
      })
    }
    if (LanguageSelect.indexOf('Traditional') >= 0) {
      this.list.push({
        title: '中文繁体',
        lang: 'ft',
      })
    }
    if (LanguageSelect.indexOf('English') >= 0) {
      this.list.push({
        title: 'English',
        lang: 'en',
      })
    }
    if (LanguageSelect.indexOf('Japanese') >= 0) {
      this.list.push({
        title: '日本語',
        lang: 'jp',
      })
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.van-popup
  background-color: #f7f8fa;
  overflow hidden
  div
    background #ffffff
    flex-center()
    height 50px
    font-size 16px
    color #323233
  .btn
    margin-top 8px
</style>
