<template>
  <div class="my-order">
    <van-row type="flex" class="nav">
      <van-col span="6">
        {{ $t('product').Contract }}
      </van-col>
      <van-col span="6">
        {{ $t('product').Amount }}
      </van-col>
      <van-col span="6">
        {{ $t('product').Direction }}
      </van-col>
      <van-col span="6">
        {{ $t('product').Profit }}
      </van-col>
    </van-row>
    <div class="list-box">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
        v-if="show"
      >
        <van-row
          type="flex"
          justify="space-between"
          v-for="(item, index) in list"
          :key="index"
          :class="{ up: item.Direction === 'Rise' }"
        >
          <van-col span="6">
            {{ showProductName(item) }}
          </van-col>
          <van-col span="6" class="direction">
            {{ basicConfig.Symbol }}{{ item.Amount }}
          </van-col>
          <van-col span="6" class="direction">
            <!-- {{
              item.Direction === 'Rise'
                ? $t('product').Increase
                : $t('product').Decrease
            }} -->
            {{ showDirection(item.Direction) }}
            {{ item.Time }}s
          </van-col>
          <van-col span="6" class="redColor" v-if="item.EndAmount !== '0.00'">
            {{ basicConfig.Symbol }}{{ item.EndAmount }}
          </van-col>
          <van-col span="6" class="redColor" v-else>
            {{ $t('product').unfinished }}
          </van-col>
        </van-row>
      </van-list>
      <div class="empty-container" v-else>
        <img src="@/assets/empty.png" alt="" />
        <div>{{ $t('product').goLogin }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
export default {
  props: {
    productList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: Token.get(),
      form: {
        page: 1,
        size: 10,
      },
    }
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig']),
  },
  methods: {
    showDirection(Direction) {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return Direction === 'Rise'
          ? basicConfig.RiseName
          : basicConfig.FallName
      } else if (lang === 'en') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameEnglish
          : basicConfig.FallNameEnglish
      } else if (lang === 'ft') {
        return Direction === 'Rise'
          ? basicConfig.RiseNameTraditional
          : basicConfig.FallNameTraditional
      } else {
        return Direction === 'Rise'
          ? basicConfig.RiseNameJapanese
          : basicConfig.FallNameJapanese
      }
    },
    async getList() {
      const res = await productApi.orderList(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list = [...this.list, ...data]
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      this.loading = false
    },
    showProductName(item) {
      let lang = this.lang
      if (lang === 'zh') {
        return item.ProductName
      } else if (lang === 'en') {
        return item.EnglishName
      } else {
        return item.TraditionalName
      }
    },
    resetList() {
      this.form.page = 1
      this.list = []
      this.getList()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.my-order
  color #666
  background-color: #1f1f1f
  padding-top 25px
  position relative
  .list-box
    height 50vh
    overflow-y scroll
  .nav
    position absolute
    top 0
    left 0
    width 100%
    background none !important
    z-index 9
    .van-col
      color #666 !important
  .van-row
    background #2C3E32
    color #999
    .direction
      color #6BB86D
    .van-col
      height 25px
      line-height 25px
      text-align center
      ellipsis()
      &:nth-of-type(1)
        color #999
  .up
    background #3c252c !important
    .direction
      color #EB4D5C !important
  .empty-container
    flex-column()
    height 100%
    img
      width 160px
      height 160px
      margin-bottom 20px
</style>
