<template>
  <div class="container">
    <Header :userInfo="userInfo" />
    <van-notice-bar left-icon="volume-o" color="#ebe5cd" background="#1f1f1f">
      <span v-for="nItem in basicConfig.CommonNotice" :key="nItem.Id" class="notice" @click="handleClick(nItem)">{{
        nItem.Content }}</span>
    </van-notice-bar>
    <div class="product-box" id="product-box">
      <div class="product-item" v-for="(item, index) in productNowInfo" :key="index"
        :class="{ active: activeIndex === index }" @click="changeProduct(index)">
        <!-- <p>{{ showProductName(item.Id) }}</p> -->
        <p>{{ item.name }}</p>
        <p>
          <span :class="{ upIn: item.Rise === true }">{{ item.Price }}</span>
          <i :class="{
            up: item.Rise === true,
          }"></i>
        </p>
      </div>
    </div>
    <div class="nowInfo">
      <span>{{ $t('product').AveragePrice }}： {{ averagePrice }}</span>
      <span>{{ $t('product').Time }}：
        {{ averagePriceTime.slice(0, averagePriceTime.length - 3) }}</span>
    </div>
    <div class="buy-nav">
      <span class="buy-mode" v-for="(bItem, bIndex) in buyModeList" :key="bIndex"
        :class="{ buyMoeActive: buyModeActiveIndex === bIndex }" @click="changeBuyMode(bIndex)">
        <p>{{ $t('product').TimeLimit }}</p>
        <p>
          <span class="time">{{ bItem.Time / 60 }}</span>
          {{ $t('product').Min }}
        </p>
        <!-- <p>{{ $t('product').Rate }}：{{ showRate(bItem.Win) }}%</p>
        <p>{{ $t('product').Loss }}：{{ showRate(bItem.Lose) }}%</p> -->
      </span>
    </div>
    <div class="btn-box">
      <div class="btn" @click="handleBuy(true)">
        <!-- {{ $t('product').Increase }} -->
        {{ showRiseDirection(true) }}
        <van-icon name="play" />
      </div>
      <div class="btn" @click="handleBuy(false)">
        <!-- {{ $t('product').Decrease }} -->
        {{ showRiseDirection(false) }}
        <van-icon name="play" />
      </div>
    </div>
    <div class="echar-box">
      <div class="now-data" v-if="dataMoeActiveIndex !== 0">
        <!-- <div class="item">{{ $t('product').O }}： {{ lastPrice.open }}</div>
        <div class="item">{{ $t('product').H }}： {{ lastPrice.high }}</div>
        <div class="item">{{ $t('product').L }}： {{ lastPrice.low }}</div>
        <div class="item">{{ $t('product').C }}： {{ lastPrice.close }}</div> -->
        <div class="item">{{ $t('product').O }}： {{ lastPrice.Open }}</div>
        <div class="item">{{ $t('product').H }}： {{ lastPrice.High }}</div>
        <div class="item">{{ $t('product').L }}： {{ lastPrice.Low }}</div>
        <div class="item">{{ $t('product').C }}： {{ lastPrice.Close }}</div>
      </div>
      <div class="echars" v-show="dataMoeActiveIndex === 0">
        <EcharI :width="width" :height="height" :Ldata="Ldata" :split="split" />
      </div>
      <div class="echars" v-show="dataMoeActiveIndex !== 0">
        <EcharK :dayMode="dataMoeActiveIndex === 5 ? true : false" :width="width" :Kdata="Kdata" :height="height"
          id="b" />
      </div>
    </div>

    <van-swipe vertical class="notice-swipe" ref="noticeSwipe" :autoplay="1000" :show-indicators="false">
      <van-swipe-item v-for="(pItem, pIndex) in allPullOrder" :key="pIndex">{{
        pItem
      }}</van-swipe-item>
    </van-swipe>
    <div class="data-mode-box">
      <div v-for="(dItem, dIndex) in dataModeList" :key="dIndex" :class="{ dataMoeActive: dataMoeActiveIndex === dIndex }"
        @click="changedataMode(dIndex)">
        {{ dItem }}
      </div>
    </div>
    <div class="order-container">
      <div class="order-btn-box">
        <div class="btn" :class="{ orderMoeActive: orderMoeActiveIndex === 0 }" @click="changeorderMode(0)">
          {{ $t('product').LatestDeal }}
        </div>
        <div class="btn" :class="{ orderMoeActive: orderMoeActiveIndex === 1 }" @click="changeorderMode(1)">
          {{ $t('product').RecentTransaction }}
        </div>
      </div>
      <div v-if="orderMoeActiveIndex === 0">
        <OrderList :list="pushOrder" :productList="productList" />
      </div>
      <div v-else>
        <MyOrder :productList="productList" ref="MyOrder" />
      </div>
    </div>
    <van-popup v-model="show" closeable position="bottom" @opened="handleOpenPopup" @closed="handleClosePopup">
      <BuyInfo ref="BuyInfo" @getInfo="handleRefresh" :isRise="isRise" :buyProduct="productList[activeIndex]"
        :buyTimeMode="buyModeActiveIndex" :productNowInfo="productNowInfo" :lastOrderInfo="orderInfo"
        :productList="productList" @submit="show = false" />
    </van-popup>
    <TabBar />
  </div>
</template>

<script>
import EcharK from './components/echarK'
import EcharI from './components/echarI'
import { EventBus } from '@/utils/event-bus'
import Header from './components/header'
import OrderList from './components/orderList'
import MyOrder from './components/myOrder'
import BuyInfo from './components/buyInfo'
import TabBar from '@/components/tabBar'
import productApi from '@/api/product'
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'

export default {
  components: {
    EcharK,
    OrderList,
    MyOrder,
    BuyInfo,
    Header,
    TabBar,
    EcharI,
  },
  data() {
    return {
      activeIndex: 0,
      buyModeActiveIndex: 0,
      dataMoeActiveIndex: 1,
      orderMoeActiveIndex: 0,
      show: false,
      lastPrice: {},
      Kdata: [],
      averagePrice: null,
      averagePriceTime: '',
      allPullOrder: [],
      pushOrder: [], // 推动的订单
      orderInfo: {},
      isRise: false,
      Ldata: [],
      ProductId: null,
      averageList: [],
      split: [],
      userInfo: {},
      buyModeList: [],
      productList: [],
      productNowInfo: [],
      dataModeList: [],
      width: '100vw',
      height: '67vw',
      newPrice: null,
    }
  },
  watch: {
    ProductId(newValue) {
      this.averageList.forEach((aItem) => {
        if (aItem.Id === newValue) {
          this.averagePrice = aItem.Average
          this.averagePriceTime = aItem.Time
          return
        }
      })
    },
  },
  computed: {
    lang() {
      this.change()
      return this.$store.getters.lang
    },
    ...mapGetters(['connection', 'basicConfig', 'newitemprice', 'productId']),
  },
  methods: {
    showRate(rate) {
      return parseFloat((rate * 100).toFixed(2))
    },
    showRiseDirection(mode) {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return mode ? basicConfig.RiseName : basicConfig.FallName
      } else if (lang === 'en') {
        return mode ? basicConfig.RiseNameEnglish : basicConfig.FallNameEnglish
      } else if (lang === 'ft') {
        return mode
          ? basicConfig.RiseNameTraditional
          : basicConfig.FallNameTraditional
      } else {
        return mode
          ? basicConfig.RiseNameJapanese
          : basicConfig.FallNameJapanese
      }
    },
    change() {
      let product = this.$t('product')
      this.dataModeList = [
        product.Trend,
        '1' + product.smallMin,
        '5' + product.smallMin,
        '15' + product.smallMin,
        '1' + product.hour,
        '1' + product.dayMin,
      ]
    },
    getNewPrice() {
      this.productList.map((item) => {
        if (item.Id == this.ProductId) {
          this.$store.commit('SET_NEWITEMPRICE', item.Price)
        }
      })
    },
    // 选择产品
    changeProduct(index) {
      this.activeIndex = index
      this.ProductId = this.productList[index].Id
      this.getNewPrice()
      this.dataMoeActiveIndex = 1
      this.getbuyModeList(index)
      //console.log('走了这222')
      this.getKData(1)
      this.OrderCash(this.ProductId)
    },
    async getUserInfo(first = false) {
      let res = await userApi.userInfo()
      this.userInfo = res
      if (!res.IsBet && first) {
        this.getLastOrder()
      }
    },
    // 选择购买时间模式
    changeBuyMode(index) {
      this.buyModeActiveIndex = index
    },
    // 选择分时图，1M,3M,5M,15M
    changedataMode(index) {
      this.dataMoeActiveIndex = index
      console.log('走了这333')
      this.getKData(index)
    },
    // 列表显示数据 最新成交、最近交易
    changeorderMode(index) {
      this.orderMoeActiveIndex = index
    },
    handleBuy(mode) {
      if (Token.get()) {
        this.isRise = mode
        this.show = true
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    showProductName(ProductId) {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === ProductId) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })

      return name
    },
    // 获得购买选项
    getbuyModeList(index) {
      this.buyModeList = this.productList[index].Proportion
    },
    async getList() {
      this.productList = await productApi.list()
      this.productList.forEach((item) => {
        item.name = this.showProductName(item.Id)
      })
      const ProductId = this.$route.params.id
      this.ProductId = ProductId ? ProductId : this.productList[0].Id
      this.getNewPrice()
      this.Basic()
      console.log('走了这 4444')
      this.getKData(1)
    },
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
      // 信息处理
      this.productBasic.map((item) => {
        item.difference = item.Price - item.BasicPrice
        item.difference = Number(item.difference).toFixed(2)
        item.percentage = (item.difference / item.Price) * 100
        item.percentage = Number(item.percentage).toFixed(2)
        return item
      })
      console.log('productBasic', this.productBasic)
      // productlist赋值
      for (let i = 0; i < this.productList.length; i++) {
        const p = this.productList[i]
        for (let j = 0; j < this.productBasic.length; j++) {
          const b = this.productBasic[j]
          if (p.Id === b.Id) {
            p.BasicPrice = b.BasicPrice
            p.Low = b.Low
            p.Price = b.Price
            p.Rise = b.Rise
            p.Top = b.Top
            p.difference = b.difference
            p.percentage = b.percentage
          }
        }
      }
      this.$store.commit('SET_PRODUCTLIST', this.productList)
      this.productNowInfo = this.productList
      console.log('设置产品价格', this.productNowInfo)
      this.$forceUpdate()
      // 到此处productlist初次获取值已经完成
    },
    async getAverage(nowStart) {
      if (!nowStart) {
        const date = new Date()
        if (date.getSeconds() !== 0) {
          return
        }
      }
      this.averageList = await productApi.Average()
      this.averageList.forEach((aItem) => {
        if (aItem.Id === this.ProductId) {
          this.averagePrice = aItem.Average
          this.averagePriceTime = aItem.Time
          return
        }
      })
    },
    async getKData(mode) {
      let res
      switch (mode) {
        case 1:
          res = await productApi.get1M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 2:
          res = await productApi.get5M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 3:
          res = await productApi.get15M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 4:
          res = await productApi.get1Hour(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 5:
          res = await productApi.getOneDay(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        default:
          this.Ldata = []
          this.getLata()
          this.inTerval()
          this.split = []
      }
      this.Kdata = res
    },
    inTerval() {
      // this.newPrice = this.Ldata[this.Ldata.length]
      this.timer1 = setInterval(() => {
        this.Ldata.push(this.newPrice)
        this.split.push('')
        if (this.Ldata.length > 100) {
          this.Ldata.shift()
          this.split.shift()
        }
      }, 1000)
    },
    async getSecondData(data) {
      // data是推送来的数据
      let productNowInfo = data
      console.log('心脏跳动')
      this.productList.forEach((item) => {
        // 所有列表
        productNowInfo.forEach((sitem) => {
          // 推送列表
          if (item.Id === sitem.ProductId) {
            item.Price = sitem.Price
            item.Rise = sitem.Rise
          }
          if (
            item.Id === sitem.ProductId &&
            sitem.ProductId == this.ProductId
          ) {
            this.$store.commit('SET_NEWITEMPRICE', sitem.Price)
          }
        })
      })
      function sortId(a, b) {
        return a.Sort - b.Sort
      }
      productNowInfo.sort(sortId)
      this.productNowInfo = this.productList
      if (!this.dataMoeActiveIndex) {
        // 在这里可以获取ldata初始数据
        data.forEach((item) => {
          if (item.ProductId === this.ProductId) {
            this.newPrice = item.Price
            if (this.Ldata.length > 100) {
              this.Ldata.shift()
              this.split.shift()
            }
          }
        })
      }
    },
    async getLata() {
      const result = await productApi.getOneSecondPrices(this.ProductId)
      result.forEach((item) => {
        this.Ldata.push(item.Price)
      })
      // 初始化容器的值
      this.newPrice = this.Ldata[this.Ldata.length - 1]
    },
    // 最后的订单
    async getLastOrder() {
      const res = await productApi.lastOrder()
      if (res.SurplusTime > 0) {
        this.show = true
      } else {
        this.show = false
      }
      this.orderInfo = res
    },
    handleClick(notice) {
      this.$dialog.alert({
        title: notice.Title,
        message: notice.Content,
      })
    },
    handleClosePopup() {
      if (this.userInfo.IsBet) {
        this.$refs.BuyInfo.clearOrderInfo()
      }
    },
    handleOpenPopup() {
      if (!this.userInfo.IsBet) {
        this.$refs.BuyInfo.handleOrderInfo()
      }
    },
    handleRefresh() {
      this.getUserInfo()
      if (this.orderMoeActiveIndex === 1) {
        this.$refs.MyOrder.resetList()
      }
    },
    firstSetTabProduct() {
      
      console.log('firstSetTabProduct.firstEnter', this.produfirstEnterctList)
      if (this.firstEnter) {
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          this.$nextTick(() => {
            if (index > 2) {
              let offsetLeft =
                document.getElementsByClassName('product-item')[index - 1]
                  .offsetLeft
              document.getElementById('product-box').scrollLeft = offsetLeft
            }
          })
          this.getbuyModeList(index)
          console.log('走了这111')
          this.getKData(1)
          this.OrderCash(this.ProductId)
          return
        }
        this.getNewPrice()
      })
    },
    async OrderCash(id) {
      this.pushOrder = await productApi.OrderCash(id)
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      connection.on('CreateOrder', function (res) {
        res.forEach((rItem) => {
          if (that.pushOrder[0].ProductId === rItem.ProductId) {
            that.pushOrder.unshift(rItem)
            if (that.pushOrder.length > 100) {
              that.pushOrder.pop()
            }
            return
          }
        })
      })
      connection.on('OrderSettle', function () {
        EventBus.$emit('OrderSettle')
      })
      connection.on('OrderDetail', function (res) {
        that.allPullOrder.push(res)
        that.allPullOrder.push('')
        if (that.allPullOrder.length > 30) {
          that.allPullOrder.shift()
          that.allPullOrder.shift()
        }
      })
      connection.on('secondPrices', function (res) {
        that.getSecondData(res)
        that.firstSetTabProduct()
      })
    },
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.firstEnter = false
    this.getList()
    this.getAverage(true)

    this.signalRStart()
    if (Token.get()) {
      this.getUserInfo(true)
    }
    this.timer = setInterval(() => {
      this.getAverage(false)
    }, 1000)
  },
}
</script>
<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.container
  width 100%
  background-color #1f1f1f
  min-height 120vh
  font-size 12px
  color #fff
  overflow-x hidden
  padding 60px 0
  .van-notice-bar
    height 26px
    margin-top -10px
    border-bottom 2px solid #000
    border-top 2px solid #000
    >>> .van-notice-bar__content
      font-size 12px !important
    .notice
      margin-right 60px
  .echars
    padding-top 10px
    border-top 2px solid #000
  .product-box
    margin 10px 2% 0
    border 1px solid #daba7b
    height 52px
    border-radius 5px
    white-space nowrap
    overflow-x auto
    overflow-y hidden
    .product-item
      width 30vw
      height 100%
      display inline-block
      text-align center
      padding 5px 0
      color #ebe5cd
      .upIn
        color #e22741
      p
        margin 6px
        span
          color #31ad91
        i
          display inline-block
          width 10px
          height 10px
          margin-left 5px
          background url('~@/assets/direction.png') left top no-repeat
          background-size 100%
          transform rotate(-180deg) rotate(-90deg)
        .up
          background-position 0 -14px
          transform rotate(-90deg)
    .active
      color #0d0b0b
      background linear-gradient(315deg, #e8d49e, #d6b372)
  .nowInfo
    display flex
    justify-content space-between
    color #ebe5cd
    height 36px
    align-items center
    margin 0 20px
  .buy-nav
    white-space nowrap
    overflow-x auto
    overflow-y hidden
    height 100px
    padding-right 25px
    .buy-mode
      display inline-block
      width 90px
      height 90px
      border-radius 50%
      margin-top 7.5px
      text-align center
      background linear-gradient(315deg, #55626c, #4e5861, #3b4046)
      color #bcc4c9
      margin-left 25px
      p
        margin 14px 0
        &:nth-of-type(1)
          margin-top 10px
      .time
        font-size 20px
        font-weight bold
    .buyMoeActive
      background linear-gradient(315deg, #fdcb83, #ddd0ad, #d6b372)
      color #000
  .echar-box
    position relative
    .now-data
      position absolute
      left 20px
      top 13px
      color #626263
      line-height 16px
  .btn-box
    margin 15px
    flex-center()
    .btn
      width 32vw
      margin 0 4vw
      height 35px
      border-radius 35px
      background-color #ed4b5c
      flex-center()
      font-size 14px
      .van-icon
        margin-left 5px
        transform rotate(-90deg)
      &:nth-of-type(2)
        background-color #6bb86d
        .van-icon
          transform rotate(90deg)
  .van-swipe
    height 25px
    line-height 25px
    .van-swipe-item
      text-align center
      font-size 14px
      color #ded9d9
  .data-mode-box
    display flex

    color #ebe5cd
    text-align center
    background #2a2626
    font-size 10px
    div
      flex 1
      display flex
      align-items center
      justify-content center
      height 35px
      border-right 1px solid #06090e
    .dataMoeActive
      border-bottom 2px solid #dab97a
      color #dab97a
  .order-btn-box
    margin 15px 3vw 30px
    flex-center()
    height 32px
    color #ebe5cd
    font-size 14px
    background #2a2626
    border-radius 16px
    overflow hidden
    .btn
      flex 1
      flex-center()
      height 100%
    .orderMoeActive
      background linear-gradient(315deg, #e8d49e, #d6b372)
      color #0a0808
</style>
