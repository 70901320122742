<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'a',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    Ldata: {
      type: Array,
      default() {
        return []
      },
    },
    split: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  watch: {
    Ldata() {
      this.drawLine()
    },
  },
  methods: {
    drawLine() {
      this.myChart.setOption(this.setLOption())
    },

    setLOption() {
      return {
        grid: {
          show: false,
          left: '8%',
          right: '12%',
          bottom: '10%',
          top: '5%',
        },
        xAxis: {
          type: 'category',
          data: this.split,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed',
            },
          },
        },
        yAxis: {
          type: 'value',
          scale: true,
          position: 'right',
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed',
            },
          },
        },
        series: [
          {
            data: this.Ldata,
            type: 'line',
          },
        ],
      }
    },
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
      this.drawLine()
    })
  },
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
