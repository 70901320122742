import { fetch, post } from '@/utils/axios'

export default {
  getOneSecondPrices: (id) => {
    return fetch(`/Event/${id}/OneSecondPrices`)
  },
  list: () => {
    return fetch('/Event/Products')
  },
  get1M: (id) => {
    return fetch(`/Event/${id}/OneMinutePrices`)
  },
  get3M: (id) => {
    return fetch(`/Event/${id}/ThreeMinutePrices`)
  },
  get5M: (id) => {
    return fetch(`/Event/${id}/FiveMinutePrices`)
  },
  get15M: (id) => {
    return fetch(`/Event/${id}/FifteenMinutePrices`)
  },
  get1Hour: (id) => {
    return fetch(`/Event/${id}/OneHourPrices`)
  },
  getOneDay: (id) => {
    return fetch(`/Event/${id}/DayPrices`)
  },
  createOrder: (data) => {
    return post('/app/Order/create', data)
  },
  lastOrder: () => {
    return fetch('/app/Order/detail')
  },
  orderList: (params) => {
    return fetch('/app/Order', params)
  },
  Average: () => {
    return fetch('/Event/Products/Average')
  },
  OrderCash: (id) => {
    return fetch(`/Event/${id}/OrderCash`)
  },
  Basic: () => {
    return fetch('/Event/Products/Basic')
  },
}
